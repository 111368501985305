@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.container {
  max-width: 1680px !important;
}

.pr-2 {
  padding-right: 2em;
}

.pl-2 {
  padding-left: 2em;
}

@media (max-width: 600px) {
  .flex-md-column {
    flex-direction: column !important;
  }
}

.offcanvas-header {
  background-color: #13334c !important;
}
.offcanvas-header button {
  --bs-btn-close-color: white!important;
  color: white;
}

.offcanvas-body {
  background-color: #13334c !important;
}
.offcanvas-body .nav-link {
  margin-bottom: 1em;
}
.offcanvas-body * {
  color: white !important;
}

body {
  position: relative;
  background-color: #13334c;
  backdrop-filter: blur(50px);
}
body .btn-primary {
  background-color: rgb(212, 175, 55);
  border: 1px solid rgb(212, 175, 55) !important;
  letter-spacing: 2px;
}
body .btn-primary:hover {
  background-color: #13334c;
  color: rgb(212, 175, 55);
}
body h1 {
  letter-spacing: 1px;
  font-size: 2.3em;
}
body h2 {
  letter-spacing: 1px;
  font-size: 1.2em;
}
body p {
  letter-spacing: 1px;
  font-size: 14px;
}
body * {
  font-family: "Roboto", "sans-serif" !important;
}
body .section {
  min-height: 700px;
}
body .section .inner_container {
  min-height: 650px;
}
body .section * {
  color: white;
}
body .section .tech_icons svg, body .section .tech_icons i {
  font-size: 80px !important;
}
body .section .experience {
  display: grid;
  grid-template-columns: 30% 70%;
  transition: 0.3s ease-in-out;
}
body .section .experience .company_name {
  font-weight: bold;
}
body .section .experience .job_description {
  font-weight: 300;
}
body .section .experience * {
  transition: 0.3s ease-in-out;
}
body .section .experience:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Semi-transparent white background */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better contrast */
  backdrop-filter: blur(20px);
}
body .section .experience .tech_stack_wrapper .tech_stack_item {
  padding-left: 1em !important;
  padding-right: 1em !important;
  background-color: rgb(212, 175, 55);
}
body .projects-section {
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: hidden;
}
body .projects-section .projects {
  backdrop-filter: blur(15px);
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 5%;
}
body .projects-section .projects h1 {
  color: white;
}
body .projects-section .projects .projects-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  vertical-align: middle;
}
body .projects-section .projects .projects-wrapper .row {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
@media (max-width: 500px) {
  body .projects-section .projects .projects-wrapper .row {
    display: flex;
  }
}
@media (max-width: 1000px) {
  body .projects-section .projects .projects-wrapper .row {
    grid-template-columns: 21em 21em;
  }
}
@media (max-width: 670px) {
  body .projects-section .projects .projects-wrapper .row {
    grid-template-columns: 20em;
  }
}
body .projects-section .projects .projects-wrapper .row .project-card {
  position: relative;
  max-width: 500px !important;
  width: 50%;
  margin-right: 15px;
  height: 300px;
}
@media (max-width: 500px) {
  body .projects-section .projects .projects-wrapper .row .project-card {
    margin-bottom: 10px;
    width: 19em !important;
  }
}
body .projects-section .projects .projects-wrapper .row .project-card img {
  border-radius: 10px !important;
  width: 100%;
  height: 100%;
  min-width: 500px;
}
body .projects-section .projects .projects-wrapper .row .project-card .card-body {
  width: 100%;
  border-radius: 10px !important;
  visibility: hidden;
  position: absolute;
  max-width: 100%;
  height: 60%;
  color: white;
  display: flex;
  flex-direction: column;
  bottom: 0;
  background: rgba(19, 51, 76, 0.8);
  backdrop-filter: blur(15px);
  justify-content: center;
  transform: translateY(15%);
  transition: all 0.38s ease-in-out;
}
body .projects-section .projects .projects-wrapper .row .project-card .card-body .cardbodytitle {
  color: white;
}
body .projects-section .projects .projects-wrapper .row .project-card .card-body .card-text {
  flex: 1;
  color: #f6f6e9;
}
body .projects-section .projects .projects-wrapper .row .project-card .card-body .link {
  flex: 0;
}
body .projects-section .projects .projects-wrapper .row .project-card:hover .card-img-top {
  filter: blur(1px);
  transition: all 0.38s ease-in-out;
}
body .projects-section .projects .projects-wrapper .row .project-card:hover .card-body {
  transform: translateY(1%);
  visibility: visible;
}

header {
  transition: 0.3s ease-in-out;
  position: fixed;
  top: 0;
  width: 100%;
  display: block;
  background-color: #13334c !important;
  /*
     * 3DXY
     */
  /*
     * 3DXY Reverse
     */
  /*
     * Arrow
     */
  /*
     * Arrow Right
     */
  /*
     * Arrow Alt
     */
  /*
     * Arrow Alt Right
     */
  /*
     * Arrow Turn
     */
  /*
     * Arrow Turn Right
     */
  /*
     * Boring
     */
  /*
     * Collapse
     */
  /*
     * Collapse Reverse
     */
  /*
     * Elastic
     */
  /*
     * Elastic Reverse
     */
  /*
     * Emphatic
     */
  /*
     * Emphatic Reverse
     */
  /*
     * Minus
     */
  /*
     * Slider
     */
  /*
     * Slider Reverse
     */
  /*
     * Spin
     */
  /*
     * Spin Reverse
     */
  /*
     * Spring
     */
  /*
     * Spring Reverse
     */
  /*
     * Stand
     */
  /*
     * Stand Reverse
     */
  /*
     * Squeeze
     */
  /*
     * Vortex
     */
  /*
     * Vortex Reverse
     */
}
header .row {
  transition: padding 0.3s ease-in-out;
}
header.sticky_header .row {
  padding: 0.2em !important;
}
header .desktop_menu {
  gap: 1em;
}
header .desktop_menu .blog_navbar {
  transition: 0.3s ease-in-out;
}
header .desktop_menu .blog_navbar:hover {
  border-color: rgb(212, 175, 55) !important;
  color: rgb(212, 175, 55);
}
header .desktop_menu .nav-link {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease-in-out;
  letter-spacing: 2px;
}
header .desktop_menu .nav-link:hover {
  color: rgb(212, 175, 55);
}
header .desktop_menu .nav-link:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: rgb(212, 175, 55);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
header .desktop_menu .nav-link:hover:after {
  width: 100%;
  left: 0;
}
header .desktop_menu .blog_navbar:hover::after {
  display: none !important;
}
@media (max-width: 550px) {
  header .mobile_menu {
    display: flex !important;
  }
}
header .btn:hover {
  background-color: transparent;
  border: none;
}
header .hamburger-inner::after,
header .hamburger-inner::before {
  background-color: white !important;
}
header .btn-check:checked + .btn,
header .btn.active,
header .btn.show,
header .btn:first-child:active,
header :not(.btn-check) + .btn:active {
  background-color: transparent !important;
  border: none !important;
}
header .hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
header .hamburger:hover {
  opacity: 0.7;
}
header .hamburger.is-active:hover {
  opacity: 0.7;
}
header .hamburger.is-active .hamburger-inner,
header .hamburger.is-active .hamburger-inner::before,
header .hamburger.is-active .hamburger-inner::after {
  background-color: #000;
}
header .hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}
header .hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
header .hamburger-inner,
header .hamburger-inner::before,
header .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
header .hamburger-inner::before,
header .hamburger-inner::after {
  content: "";
  display: block;
}
header .hamburger-inner::before {
  top: -10px;
}
header .hamburger-inner::after {
  bottom: -10px;
}
header .hamburger--3dx .hamburger-box {
  perspective: 80px;
}
header .hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
header .hamburger--3dx .hamburger-inner::before,
header .hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
header .hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg);
}
header .hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
header .hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}
header .hamburger--3dx-r .hamburger-box {
  perspective: 80px;
}
header .hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
header .hamburger--3dx-r .hamburger-inner::before,
header .hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
header .hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg);
}
header .hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
header .hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}
header .hamburger--3dy .hamburger-box {
  perspective: 80px;
}
header .hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
header .hamburger--3dy .hamburger-inner::before,
header .hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
header .hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg);
}
header .hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
header .hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}
header .hamburger--3dy-r .hamburger-box {
  perspective: 80px;
}
header .hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
header .hamburger--3dy-r .hamburger-inner::before,
header .hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
header .hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg);
}
header .hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
header .hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}
header .hamburger--3dxy .hamburger-box {
  perspective: 80px;
}
header .hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
header .hamburger--3dxy .hamburger-inner::before,
header .hamburger--3dxy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
header .hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg);
}
header .hamburger--3dxy.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
header .hamburger--3dxy.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}
header .hamburger--3dxy-r .hamburger-box {
  perspective: 80px;
}
header .hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
header .hamburger--3dxy-r .hamburger-inner::before,
header .hamburger--3dxy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
header .hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
}
header .hamburger--3dxy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
header .hamburger--3dxy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}
header .hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
header .hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}
header .hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}
header .hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
header .hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
header .hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
header .hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
header .hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
header .hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
header .hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
header .hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
header .hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
header .hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}
header .hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}
header .hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
header .hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg);
}
header .hamburger--arrowturn-r.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
header .hamburger--arrowturn-r.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}
header .hamburger--boring .hamburger-inner,
header .hamburger--boring .hamburger-inner::before,
header .hamburger--boring .hamburger-inner::after {
  transition-property: none;
}
header .hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}
header .hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
header .hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}
header .hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
header .hamburger--collapse .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
header .hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
header .hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
header .hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
header .hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
header .hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
header .hamburger--collapse-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
header .hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
header .hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
header .hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
header .hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
header .hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
header .hamburger--elastic .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease;
}
header .hamburger--elastic .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
header .hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s;
}
header .hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}
header .hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}
header .hamburger--elastic-r .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
header .hamburger--elastic-r .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease;
}
header .hamburger--elastic-r .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
header .hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.075s;
}
header .hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}
header .hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(270deg);
  transition-delay: 0.075s;
}
header .hamburger--emphatic {
  overflow: hidden;
}
header .hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}
header .hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
header .hamburger--emphatic .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
header .hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}
header .hamburger--emphatic.is-active .hamburger-inner::before {
  left: -80px;
  top: -80px;
  transform: translate3d(80px, 80px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
header .hamburger--emphatic.is-active .hamburger-inner::after {
  right: -80px;
  top: -80px;
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
header .hamburger--emphatic-r {
  overflow: hidden;
}
header .hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}
header .hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
header .hamburger--emphatic-r .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
header .hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}
header .hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -80px;
  top: 80px;
  transform: translate3d(80px, -80px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
header .hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -80px;
  top: 80px;
  transform: translate3d(-80px, -80px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
header .hamburger--minus .hamburger-inner::before,
header .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
}
header .hamburger--minus.is-active .hamburger-inner::before,
header .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
}
header .hamburger--minus.is-active .hamburger-inner::before {
  top: 0;
}
header .hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0;
}
header .hamburger--slider .hamburger-inner {
  top: 2px;
}
header .hamburger--slider .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
header .hamburger--slider .hamburger-inner::after {
  top: 20px;
}
header .hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
header .hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}
header .hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}
header .hamburger--slider-r .hamburger-inner {
  top: 2px;
}
header .hamburger--slider-r .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
header .hamburger--slider-r .hamburger-inner::after {
  top: 20px;
}
header .hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}
header .hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  opacity: 0;
}
header .hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(90deg);
}
header .hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
header .hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
header .hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
header .hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
header .hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
header .hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
header .hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
header .hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
header .hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
header .hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
header .hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
header .hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
header .hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear;
}
header .hamburger--spring .hamburger-inner::before {
  top: 10px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
header .hamburger--spring .hamburger-inner::after {
  top: 20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
header .hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}
header .hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
header .hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}
header .hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
header .hamburger--spring-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}
header .hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
header .hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
header .hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
}
header .hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}
header .hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}
header .hamburger--stand .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
header .hamburger--stand .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
header .hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}
header .hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
header .hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
header .hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}
header .hamburger--stand-r .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
header .hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
header .hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}
header .hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
header .hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
header .hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
header .hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
header .hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
header .hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
header .hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
header .hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
header .hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
header .hamburger--vortex .hamburger-inner::before,
header .hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}
header .hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity;
}
header .hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform;
}
header .hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
header .hamburger--vortex.is-active .hamburger-inner::before,
header .hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s;
}
header .hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
header .hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
}
header .hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
header .hamburger--vortex-r .hamburger-inner::before,
header .hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}
header .hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity;
}
header .hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform;
}
header .hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
header .hamburger--vortex-r.is-active .hamburger-inner::before,
header .hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s;
}
header .hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
header .hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

footer {
  background-color: rgba(19, 51, 76, 0.3) !important;
  backdrop-filter: blur(55px);
  margin-top: 2em;
}
footer .my_social_links {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 2em;
  padding-top: 2em;
}
footer .my_social_links a {
  color: white;
  font-size: 25px;
  max-width: 50px;
  text-decoration: none;
  transition: 0.3s ease-in-out;
}
footer .my_social_links a:hover {
  transform: scale(1.1);
  color: rgb(212, 175, 55);
}
footer .my_social_links a svg {
  min-width: 55px !important;
  width: 55px !important;
}

.col-lg-2, .col-lg-4 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (max-width: 550px) {
  .offcanvas-body .blog_navbar {
    background-color: rgb(212, 175, 55) !important;
    color: white;
    border: rgb(212, 175, 55);
  }
  .offcanvas-body .blog_navbar a {
    line-height: 3em !important;
    width: 100% !important;
    padding-left: 2em !important;
  }
}

.slide-right {
  animation: slide-right 0.4s linear both;
}

.animation_delay_03s {
  animation-delay: 0.3s;
}

.animation_delay_04s {
  animation-delay: 0.4s;
}

@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.slide-left {
  animation: slide-left 0.4s linear both;
}

@keyframes slide-left {
  0% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0);
  }
}

